import { range, find, isEqual, isEmpty } from 'underscore';
import { na1 } from 'hubspot-url-utils/hublets';
import enviro from 'enviro';
import memoizeOne from 'react-utils/memoizeOne';
import * as FieldTypes from 'ContentUtils/constants/CustomWidgetFieldTypes';
import { ACCESS_OPERATORS, BOOLEAN_OPERATORS, OPERATORS, VISIBILITY_RULES } from 'ContentUtils/constants/DisplayConditions';
import { getDefaultScalarValue, valueIsArray } from 'ContentUtils/helpers/FieldHelpers';
import Routes from 'ContentUtils/Routes';
import InheritenceTypes from 'ContentUtils/constants/CustomWidgetInheritanceTypes';
import { CONTENT, DATA, STYLE, VISIBILITYGROUP } from 'ContentUtils/constants/CustomWidgetFieldGroupTabTypes';
import { userInfoSync } from 'hub-http/userInfo';
import getLang from 'I18n/utils/getLang';
import { getTranslatedFields, getIsBlogContentModule, getTranslatedBlogContentFields, getTranslation } from 'ContentUtils/helpers/TranslationsHelpers';
import { getContentSchemaFields } from 'ContentUtils/helpers/GraphqlHelpers';
export function isNullOrUndefined(value) {
  return value === null || value === undefined;
}
export function getDeepValue(searchObject, valueKey) {
  if (!Array.isArray(valueKey) || isNullOrUndefined(searchObject) || typeof searchObject !== 'object') {
    return undefined;
  }

  let current = JSON.parse(JSON.stringify(searchObject));
  let i = 0;

  while (i < valueKey.length) {
    if (isNullOrUndefined(current) || typeof current !== 'object') {
      return undefined;
    }

    current = current[valueKey[i]];
    i = i + 1;
  }

  return current;
}
/***
Returns module data for a field group referred to by valueKey
Includes all data from top level group as well as specific occurrences
Example:
  moduleData = {
    "group": [
      {
        "group2": [
          { "foo": "bar" },
          { "foo": "baz" }
        ],
        "text": "hey"
      }
    ]
  }
 i.e. buildGroupValue(moduleData, ["group", 0, "group2", 1]) =>
  {
    "group": {
      "group2": { "foo": "baz" },
      "text": "hey"
    }
  }
***/

export function buildGroupValue(searchObject, valueKey) {
  if (!Array.isArray(valueKey) || !searchObject) {
    return undefined;
  }

  if (!valueKey.length || typeof searchObject !== 'object') {
    return searchObject;
  }

  const key = valueKey[0];
  const value = searchObject[key];

  if (isNullOrUndefined(value)) {
    return searchObject;
  }

  if (typeof key === 'number') {
    return buildGroupValue(value, valueKey.slice(1));
  } else if (typeof key === 'string') {
    return Object.assign({}, searchObject, {
      [key]: buildGroupValue(value, valueKey.slice(1))
    });
  }

  return searchObject;
}
export function getGroupValueFromKey(groupValue, valueKey) {
  if (!Array.isArray(valueKey) || !groupValue) {
    return undefined;
  }

  if (!valueKey.length || typeof groupValue !== 'object') {
    return groupValue;
  }

  const key = valueKey[0];

  if (typeof key === 'number') {
    return getGroupValueFromKey(groupValue, valueKey.slice(1));
  }

  if (isNullOrUndefined(groupValue[key])) {
    return undefined;
  }

  if (typeof key === 'string') {
    return getGroupValueFromKey(groupValue[key], valueKey.slice(1));
  }

  return groupValue;
}
export function setDeepValue(originalObj, valueKey, value) {
  const key = valueKey[0];
  let obj;

  if (!originalObj) {
    obj = typeof key === 'number' ? [] : {};
  } else {
    obj = JSON.parse(JSON.stringify(originalObj));
  }

  if (!Array.isArray(valueKey) || !valueKey || !valueKey.length) {
    return obj;
  }

  if (valueKey.length === 1) {
    obj[key] = value;
  } else {
    obj[key] = setDeepValue(obj[key], valueKey.slice(1), value);
  }

  return obj;
}
export function isObject(item) {
  return item && typeof item === 'object' && !Array.isArray(item);
}
export const capitalize = value => value.replace(value[0], value[0].toUpperCase()); // Deep merge function that merges arrays

function mergeDeep2(target, source) {
  const bothObjects = isObject(target) && isObject(source);
  const bothArrays = Array.isArray(target) && Array.isArray(source);

  if (!bothObjects && !bothArrays) {
    return source;
  }

  if (Array.isArray(target)) {
    const output = [...target];
    source.forEach((sourceValue, index) => {
      const targetValue = target[index];
      output[index] = mergeDeep2(targetValue, sourceValue);
    });
    return output;
  }

  const output = Object.assign({}, target);
  Object.keys(source).forEach(key => {
    const targetValue = target[key];
    const sourceValue = source[key];
    output[key] = mergeDeep2(targetValue, sourceValue);
  });
  return output;
} // Jacked this from https://stackoverflow.com/a/37164538


export function mergeDeep(target, source) {
  if (Routes.isUngated('Cms:FixDefaultValuesInGroups')) {
    return mergeDeep2(target, source);
  }

  const output = Object.assign({}, target);

  if (isObject(target) && isObject(source)) {
    Object.keys(source).forEach(key => {
      if (isObject(source[key])) {
        if (!(key in target)) {
          Object.assign(output, {
            [key]: source[key]
          });
        } else {
          output[key] = mergeDeep(target[key], source[key]);
        }
      } else {
        Object.assign(output, {
          [key]: source[key]
        });
      }
    });
  }

  return output;
} // determines if a field has a default or min occurrence set and
// its default value is an array. If it's an array type field like
// simple menu it should be a 2-dimensional array

export function defaultValueIsOccurrenceArray(field) {
  return field.occurrence && field.default && Array.isArray(field.default) && (!valueIsArray(field) || Array.isArray(field.default[0]));
}
export function getNewOccurrenceValue(field, isTopLevel = true) {
  const {
    occurrence = {}
  } = field;
  const defaultOccurrence = occurrence.default || occurrence.min;

  if (field.type === FieldTypes.GROUP && field.children) {
    const newOccurrenceValue = field.children.reduce((valueMap, _child) => {
      valueMap[_child.name] = getNewOccurrenceValue(_child, false);
      return valueMap;
    }, {});

    if (defaultOccurrence && !isTopLevel) {
      return range(defaultOccurrence).map(() => newOccurrenceValue);
    }

    return newOccurrenceValue;
  }

  const defaultValue = getDefaultScalarValue(field);

  if (defaultOccurrence && !isTopLevel) {
    return range(defaultOccurrence).map(() => defaultValue);
  }

  return defaultValue;
} // Finds field and returns array of ancestor fields leading to it
// i.e. If field is contained in group, returns [groupField, field]

export function getFieldPath(fields, fieldId) {
  const field = find(fields, _field => {
    return _field.id === fieldId;
  });
  if (field) return [field];

  for (const _field of fields) {
    if (_field.children) {
      const _path = getFieldPath(_field.children, fieldId);

      if (_path.length) {
        return [_field, ..._path];
      }
    }
  }

  return [];
}

function getFieldAncestorsByPath(fields, pathString) {
  const fieldDotPath = getKeyfromPathString(pathString);
  let fieldsCurrent = [...fields];
  const fieldPath = [];
  fieldDotPath.forEach((fieldName, i) => {
    const field = fieldsCurrent.find(_field => _field.name === fieldName);

    if (field) {
      fieldPath.push(field);

      if (fieldDotPath.length > i + 1 && field.children) {
        fieldsCurrent = field.children;
      }
    }
  });
  return fieldPath;
}

function checkSimpleVisibility(visibility, allFields, module, userScopes = [], groupOccurrenceOptions) {
  const {
    controlling_field: controllingFieldId,
    controlling_field_path: controllingFieldPath,
    controlling_value_regex: controllingRegex,
    operator,
    access,
    property
  } = visibility;
  const {
    isCheckingIfFieldDisabled,
    groupValue
  } = groupOccurrenceOptions;

  if (controllingFieldPath || controllingFieldId) {
    // if we're searching within a group occurrence
    // look for controlling field/value within occurrence first
    // then in entire module
    const valueToSearch = groupValue ? Object.assign({}, module, {}, groupValue) : module;
    const fieldKey = controllingFieldPath ? getFieldAncestorsByPath(allFields, controllingFieldPath) : getFieldPath(allFields, controllingFieldId); // When checking if a field is disabled, returns false if the controlling
    // field cannot be found. When checking visibility, returns true if the
    // controlling field cannot be found.

    if (!fieldKey.length) return !isCheckingIfFieldDisabled;
    const controllingField = fieldKey[fieldKey.length - 1];
    let fieldValue = getDeepValue(valueToSearch, controllingFieldPath ? getKeyfromPathString(controllingFieldPath) : fieldKey.map(_field => _field.name));
    fieldValue = fieldValue === undefined ? controllingField.default : fieldValue;

    if (!isNullOrUndefined(fieldValue)) {
      if (isObject(fieldValue)) {
        fieldValue = property ? getDeepValue(fieldValue, getKeyfromPathString(property)) : JSON.stringify(fieldValue);
      } else {
        fieldValue = fieldValue.toString();
      }
    } else {
      fieldValue = '';
    }

    if (operator) {
      switch (operator) {
        case OPERATORS.EMPTY:
          return !fieldValue;

        case OPERATORS.EQUAL:
          return fieldValue === controllingRegex;

        case OPERATORS.NOT_EMPTY:
          return !!fieldValue;

        case OPERATORS.NOT_EQUAL:
          return fieldValue !== controllingRegex;

        case OPERATORS.MATCHES_REGEX:
          return fieldValue.match(controllingRegex);

        default:
          return true;
      }
    } else if (!operator && controllingRegex && fieldValue) {
      /* Some old modules have controlling regex and value, but no operator */
      return fieldValue.match(controllingRegex);
    } else if (!controllingRegex && !fieldValue) {
      // if a controlling field is set but no regex
      // just check that the value is not empty
      return false;
    }
  }

  if (access) {
    const {
      operator: accessOperator,
      scopes = [],
      gates = []
    } = access;
    if (!(scopes.length || gates.length)) return false;

    switch (accessOperator) {
      case ACCESS_OPERATORS.HAS_ALL:
        {
          const scopesPass = !scopes.length || scopes.every(scope => userScopes.includes(scope));
          const gatesPass = !gates.length || gates.every(gate => Routes.isUngated(gate));
          return scopesPass && gatesPass;
        }

      case ACCESS_OPERATORS.HAS_ANY:
        {
          const scopesPass = !scopes.length || scopes.some(scope => userScopes.includes(scope));
          const gatesPass = !gates.length || gates.some(gate => Routes.isUngated(gate));
          return scopesPass && gatesPass;
        }

      case ACCESS_OPERATORS.HAS_NONE:
        {
          const scopesPass = !scopes.length || scopes.every(scope => !userScopes.includes(scope));
          const gatesPass = !gates.length || gates.every(gate => !Routes.isUngated(gate));
          return scopesPass && gatesPass;
        }

      default:
        return false;
    }
  }

  return true;
}

function checkAdvancedVisibility(advancedVisibility, allFields, module, scopes, groupOccurrenceOptions) {
  const {
    boolean_operator: booleanOperator,
    children,
    criteria
  } = advancedVisibility;
  if (!(booleanOperator && criteria)) return true;
  let areCriteriaValid = false;
  let areChildrenValid = false;
  const hasChildrenVisibilityRules = children && children.length; // If the criteria list is empty and there's no children, just exit early.

  if (!(criteria.length || hasChildrenVisibilityRules)) return true; // Depending on the boolean operator, check that some or every criteria is valid
  // Then if there are children, recursively check each child and compare with main
  // criteria visibility.

  if (booleanOperator === BOOLEAN_OPERATORS.AND) {
    // If checking for the AND boolean operator, we need to make sure that all
    // visibility objects in criteria come back true, as well as the children.
    areCriteriaValid = criteria.every(_criteria => checkSimpleVisibility(_criteria, allFields, module, scopes, groupOccurrenceOptions)); // For the AND operator, if there are no children or the children list is empty
    // we want to default it to true so that we determine if it's visible based on
    // the result of the criteria.every check above.

    areChildrenValid = hasChildrenVisibilityRules ? children.every(child => checkAdvancedVisibility(child, allFields, module, scopes, groupOccurrenceOptions)) : true;
    return areCriteriaValid && areChildrenValid;
  }

  if (booleanOperator === BOOLEAN_OPERATORS.OR) {
    // We only need to check that one of the visibility objects in criteria is valid,
    // Array.some will return false in case the criteria list is empty. If the list is
    // empty, this will return the result of the children.
    areCriteriaValid = criteria.some(_criteria => checkSimpleVisibility(_criteria, allFields, module, scopes, groupOccurrenceOptions)); // This needs to default to false if hasChildrenVisibilityRules is false, otherwise
    // empty children lists will cause the OR case to always return true.

    areChildrenValid = hasChildrenVisibilityRules ? children.some(child => checkAdvancedVisibility(child, allFields, module, scopes, groupOccurrenceOptions)) : false;
    return areCriteriaValid || areChildrenValid;
  }

  return true;
}

export function isVisible(field, allFields, module, scopes, groupOccurrenceOptions = {}) {
  const {
    advanced_visibility: advancedVisibility,
    visibility: simpleVisibility,
    visibility_rules: visibilityRules
  } = field;

  if (!visibilityRules || visibilityRules === VISIBILITY_RULES.SIMPLE) {
    if (!simpleVisibility) return true;
    return checkSimpleVisibility(simpleVisibility, allFields, module, scopes, groupOccurrenceOptions);
  }

  if (visibilityRules === VISIBILITY_RULES.ADVANCED) {
    if (!advancedVisibility) return true;
    return checkAdvancedVisibility(advancedVisibility, allFields, module, scopes, groupOccurrenceOptions);
  }

  return true;
}
export function isFieldDisabled(field, allFields, module, scopes, groupOccurrenceOptions = {}) {
  const {
    disabled_controls
  } = field;

  if (disabled_controls && !isEmpty(disabled_controls.rules)) {
    const {
      rules
    } = disabled_controls;
    const {
      boolean_operator: booleanOperator
    } = rules;

    if (booleanOperator) {
      return checkAdvancedVisibility(rules, allFields, module, scopes, Object.assign({}, groupOccurrenceOptions, {
        isCheckingIfFieldDisabled: true
      }));
    }

    return checkSimpleVisibility(rules, allFields, module, scopes, Object.assign({}, groupOccurrenceOptions, {
      isCheckingIfFieldDisabled: true
    }));
  }

  return false;
}
export function getFieldFromKey(fields, key) {
  const keyWithoutIndices = key && key.filter(_keyName => typeof _keyName === 'string');

  if (!keyWithoutIndices || !keyWithoutIndices.length) {
    return null;
  }

  let field = null;
  keyWithoutIndices.forEach(_keyName => {
    if (field && field.children) {
      field = find(field.children, _field => _field.name === _keyName);
    } else if (!field) {
      field = find(fields, _field => _field.name === _keyName);
    }
  });
  return field;
} // Get parent of field corresponding to key

export function getBackLinkKey(key) {
  if (!key) {
    return null;
  }

  let backLinkKey = null;
  const lastKeyItem = key[key.length - 1];

  if (typeof lastKeyItem === 'number') {
    backLinkKey = key.slice(0, key.length - 2);
  } else {
    backLinkKey = key.slice(0, key.length - 1);
  }

  return backLinkKey && backLinkKey.length ? backLinkKey : null;
}
export function isValueEmpty(field, value) {
  if (field.type === FieldTypes.COLOR && value === '#') {
    return true;
  }

  return isNullOrUndefined(value) || value === '';
}
const HIDE_FIELD_LABEL_TYPES = Object.freeze({
  [FieldTypes.BOOLEAN]: true,
  [FieldTypes.IMAGE]: true,
  [FieldTypes.CTA]: true,
  [FieldTypes.ICON]: true,
  [FieldTypes.SFDC_CAMPAIGN]: true,
  [FieldTypes.VIDEO]: true,
  [FieldTypes.FILE]: true,
  [FieldTypes.BACKGROUND_IMAGE]: true
});
export function hideFieldLabel(field) {
  return !!HIDE_FIELD_LABEL_TYPES[field.type];
}
export function isOccurrenceKey(fieldKey) {
  return fieldKey && typeof fieldKey[fieldKey.length - 1] === 'number';
}
export function isOccurrenceOrChildOfOccurrenceKey(fieldKey) {
  return fieldKey && fieldKey.some(key => typeof key === 'number');
}
export function isRepeatingOrHasRepeatingChild(field) {
  const {
    occurrence,
    children
  } = field || {};
  if (occurrence) return true;
  if (children) return children.some(child => isRepeatingOrHasRepeatingChild(child));
  return false;
}
export function getGroupValue(fields, fieldKey, module, options = {}) {
  const {
    maintainOccurrenceArrays
  } = options;
  let currentField = null;
  let groupValue = null;
  fieldKey.forEach((keyItem, index) => {
    const currentFieldKey = fieldKey.slice(0, index + 1);

    if (typeof keyItem === 'string') {
      if (currentField && currentField.children) {
        currentField = currentField.children.find(_field => _field.name === keyItem);
      } else if (!currentField) {
        currentField = fields.find(_field => _field.name === keyItem);
      }

      if (currentField && currentField.type === FieldTypes.GROUP && !isNullOrUndefined(currentField.default)) {
        // We want to make sure that parent group defaults override the current group's default
        // setDeepValue will override existing values, so that's why we setDeepValue and then
        // mergeDeep back into the existing values to favor existing values
        const groupOrModuleData = groupValue || module;
        groupValue = mergeDeep(setDeepValue(groupOrModuleData, currentFieldKey, currentField.default), groupOrModuleData);
      }
    } // If we're on a specific index of a repeater, use the values at that index rather than
    // the occurrence array (unless maintainOccurrenceArrays is true - we want to use the
    // array when setting an occurrence value for the first time so that there's an array to
    // set the value on top of)


    if (!maintainOccurrenceArrays && groupValue && (isRepeatingOrHasRepeatingChild(currentField) || isOccurrenceOrChildOfOccurrenceKey(currentFieldKey))) {
      groupValue = buildGroupValue(groupValue, currentFieldKey);
    }
  });
  return groupValue;
}

const createLookahead = term => `(?=.*${term}.*)`;

export function createSearchRegExp(searchTerm) {
  const expression = searchTerm.replace(/\(|\)|\[|\]/g, '').split(/\s+/).map(createLookahead).join('');
  return new RegExp(expression, 'i');
}
export function hasSearchMatch(searchRegExp, ...values) {
  return searchRegExp.test(values.join(' '));
}
export function setFieldIds(fields, prefix) {
  return fields.map(_field => {
    const newField = Object.assign({}, _field);

    if (!newField.id) {
      newField.id = prefix ? `${prefix}.${newField.name}` : newField.name;
    }

    if (newField.children) {
      newField.children = setFieldIds(newField.children, newField.id);
    }

    return newField;
  });
}

function getKeyfromPathString(pathString = '') {
  const valueKey = [];
  pathString.split('.').forEach(key => {
    const index = key.match(/\[\d+\]/);

    if (index) {
      valueKey.push(key.replace(/\[\d+\]/, ''));
      const pathIndex = parseInt(index[0].replace(/\[|\]/, ''), 10);

      if (typeof pathIndex === 'number') {
        valueKey.push(pathIndex);
      }
    } else {
      valueKey.push(key);
    }
  }); // Since inheritance can be from brandSettings or module

  return valueKey;
}

function getFieldAndRemainingPathFromKey(fields, pathKey) {
  let field = null;
  let lastIndex = 0;

  for (let i = 0; i < pathKey.length; i++) {
    const _key = pathKey[i];
    const matchingField = field && field.children ? find(field.children, _child => _child.name === _key) : find(fields, _field => _field.name === _key);

    if (!matchingField) {
      break;
    } else {
      field = matchingField;
      lastIndex = i;
    }
  }

  return [field, pathKey.slice(lastIndex + 1)];
}

function getInheritedValueFromField(pathKey, fields = [], data = {}, inheritanceData) {
  const value = getDeepValue(data, pathKey);

  if (isNullOrUndefined(value)) {
    const [inheritedField, remainingPath] = getFieldAndRemainingPathFromKey(fields, pathKey);

    if (inheritedField) {
      const inheritedFieldDefault = getFieldDefault(inheritedField, fields, data, inheritanceData);
      return remainingPath.length ? getDeepValue(inheritedFieldDefault, remainingPath) : inheritedFieldDefault;
    }
  }

  return value;
}

function getInheritedDefaultValue(pathString, fields, moduleData, inheritanceData = {}) {
  const inheritedValueKey = pathString && getKeyfromPathString(pathString);

  if (inheritedValueKey && inheritedValueKey.length > 1) {
    const inheritanceTypeKey = inheritedValueKey[0];
    const pathKey = inheritedValueKey.slice(1);

    switch (inheritanceTypeKey) {
      case InheritenceTypes.brand_settings:
      case InheritenceTypes.brandSettings:
        {
          const {
            brandSettings = {}
          } = inheritanceData;
          return getDeepValue(brandSettings, pathKey);
        }

      case InheritenceTypes.theme:
        {
          const {
            themeFields = [],
            themeSettings = {}
          } = inheritanceData;
          return getInheritedValueFromField(pathKey, themeFields, themeSettings, inheritanceData);
        }

      default:
        {
          return getInheritedValueFromField(pathKey, fields, moduleData, inheritanceData);
        }
    }
  }

  return null;
}

function getFieldDefault(field, fields, moduleData, inheritanceData) {
  let defaultValue = field.default; // Inherited values

  if (field.inherited_value) {
    const {
      default_value_path: defaultValuePath,
      property_value_paths: propertyValuePaths
    } = field.inherited_value; // Set entire default value from inherited field

    if (defaultValuePath) {
      const inheritedValue = getInheritedDefaultValue(defaultValuePath, fields, moduleData, inheritanceData);

      if (!isNullOrUndefined(inheritedValue)) {
        defaultValue = inheritedValue;
      }
    } // For object-type fields - set individual properties


    if (propertyValuePaths && typeof propertyValuePaths === 'object') {
      defaultValue = defaultValue && typeof defaultValue === 'object' ? defaultValue : {};
      Object.keys(propertyValuePaths).forEach(key => {
        const path = propertyValuePaths[key];
        const inheritedValue = getInheritedDefaultValue(path, fields, moduleData, inheritanceData);

        if (!isNullOrUndefined(inheritedValue)) {
          const keyPath = getKeyfromPathString(key);
          defaultValue = setDeepValue(defaultValue, keyPath, inheritedValue);
        }
      });
    }
  }

  return defaultValue;
}

function _setFieldDefaults(fieldsToSet, allFields, moduleData, inheritanceData) {
  return fieldsToSet.map(_field => {
    const newField = Object.assign({}, _field);
    const defaultValue = getFieldDefault(_field, allFields, moduleData, inheritanceData);

    if (defaultValue !== undefined) {
      newField.default = defaultValue;
    }

    if (newField.children) {
      newField.children = _setFieldDefaults(newField.children, allFields, moduleData, inheritanceData);
    }

    return newField;
  });
}

export const setFieldValuesAsDefaults = memoizeOne(_setFieldValuesAsDefaults, isEqual);
const FIELD_PROPERTIES_ORDER = ['label', 'name', 'id', 'type', 'inline_help_text', 'help_text', 'visibility', 'children' // ... any other properties
// inherited_value
// default
];

function sortFieldProperties(field) {
  let sortedField = {}; // Sorts the properties that exist in FIELD_PROPERTIES_ORDER in the correct order

  FIELD_PROPERTIES_ORDER.forEach(property => {
    if (Object.prototype.hasOwnProperty.call(field, property)) sortedField[property] = field[property];
  }); // Puts the rest of the field's properties underneath the ones that are sorted
  // using FIELD_PROPERTIES_ORDER

  sortedField = Object.assign(sortedField, field); // Puts 'inheritance' and 'default' properties below all other properties

  if (Object.prototype.hasOwnProperty.call(sortedField, 'inherited_value')) {
    const inheritanceValue = sortedField.inherited_value;
    delete sortedField.inherited_value;
    sortedField.inherited_value = inheritanceValue;
  }

  if (Object.prototype.hasOwnProperty.call(sortedField, 'default')) {
    const defaultValue = sortedField.default;
    delete sortedField.default;
    sortedField.default = defaultValue;
  }

  return sortedField;
}

export function _setFieldValuesAsDefaults(fieldsToSet, moduleData, currFieldKey = []) {
  return fieldsToSet.map(_field => {
    const newField = Object.assign({}, _field);
    const _fieldKey = [...currFieldKey, _field.name];
    const fieldvalue = getDeepValue(moduleData, _fieldKey);
    if (fieldvalue !== undefined) newField.default = fieldvalue;

    if (newField.children) {
      newField.children = _setFieldValuesAsDefaults(newField.children, moduleData, _fieldKey);
    }

    return sortFieldProperties(newField);
  });
}

function doesFieldContainExternalInheritance(field) {
  if (field.inherited_value) {
    const {
      default_value_path: defaultValuePath,
      property_value_paths: propertyValuePaths
    } = field.inherited_value;
    const defaultValueKey = defaultValuePath && defaultValuePath.split('.');

    if (defaultValueKey && defaultValueKey.length) {
      return !!InheritenceTypes[defaultValueKey[0]];
    }

    if (propertyValuePaths && typeof propertyValuePaths === 'object') {
      return Object.keys(propertyValuePaths).some(key => {
        const path = propertyValuePaths[key];
        const propertyKey = path && path.split('.');
        return propertyKey && propertyKey.length && !!InheritenceTypes[propertyKey[0]];
      });
    }
  }

  return false;
}

export function doFieldsContainExternalInheritance(fields) {
  return fields.some(_field => {
    if (doesFieldContainExternalInheritance(_field)) return true;

    if (_field.children) {
      return doFieldsContainExternalInheritance(_field.children);
    }

    return false;
  });
}
export const setFieldDefaults = memoizeOne(_setFieldDefaults, isEqual);
/*
Returns false when a field should be hidden entirely because the user lacks
proper access (rather than showing an alert explaining that they lack access)
*/

export const hasAccessToField = field => {
  switch (field.type) {
    case FieldTypes.PAYMENT:
      return enviro.getHublet() === na1;

    default:
      return true;
  }
};
export function getFieldGroupForTab(fields, tab) {
  return fields.find(_field => _field.type === FieldTypes.GROUP && _field.tab === tab);
}
export function getFilteredFields(fields, props, scopes, visibilityOptions = {}) {
  const {
    module,
    hideLockedFields,
    isFieldVisible
  } = props;
  const hydratedFields = hydrateFields(props);
  return fields.reduce((_filteredFields, _field) => {
    const {
      children,
      tab,
      locked
    } = _field;
    const scopesChecked = scopes || userInfoSync().user.scopes;
    const inContentTab = !tab || tab === CONTENT;
    const inStylesTab = tab && tab === STYLE;
    const isStylesGroup = inStylesTab && !visibilityOptions.isExternalTabGroup; // If isFieldVisible exists, it should override the value
    // that is set by the locked field property. Eventually the idea
    // would be for isFieldVisible to replace the locked functionality.

    let shouldShowField = !(hideLockedFields && locked);

    if (isFieldVisible) {
      shouldShowField = isFieldVisible(_field);
    }

    if ((inContentTab || inStylesTab) && !isStylesGroup && shouldShowField && !_field.editorAdded && isVisible(_field, hydratedFields, module, scopesChecked, visibilityOptions) && hasAccessToField(_field) && !(children && !getFilteredFields(children, props, scopesChecked, visibilityOptions).length)) {
      _filteredFields.push(_field);
    }

    return _filteredFields;
  }, []);
}
export function getDataFields(module, dataQueryVariableSchema) {
  if (!isEmpty(dataQueryVariableSchema)) {
    return getContentSchemaFields(dataQueryVariableSchema, module.dataQueryVariables);
  }

  return [];
}
export function doesTabHaveFields(tab, props, scopes, visibilityOptions = {}) {
  const fields = hydrateFields(props);
  let fieldsToCheck = [];

  if (tab && tab !== CONTENT) {
    const tabGroup = getFieldGroupForTab(fields, tab);

    if (tabGroup) {
      fieldsToCheck = tabGroup.children;
      visibilityOptions.isExternalTabGroup = true;
    }
  } else {
    fieldsToCheck = fields;
  }

  const scopesChecked = scopes || userInfoSync().user.scopes;
  const filteredFields = getFilteredFields(fieldsToCheck, props, scopesChecked, visibilityOptions);
  return filteredFields.length > 0;
}
export function hydrateFields(props) {
  const {
    inheritanceData,
    module,
    moduleSpec,
    tab,
    dataQueryVariableSchema
  } = props;
  const {
    messages = {},
    masterLanguage,
    moduleId
  } = moduleSpec;
  const inDataTab = tab && tab === DATA;

  if (inDataTab && !isEmpty(dataQueryVariableSchema)) {
    const {
      dataQueryVariables = {}
    } = module;
    return getContentSchemaFields(dataQueryVariableSchema, dataQueryVariables);
  }

  const currentUserLang = getLang();
  let fields = setFieldIds(moduleSpec.fields || []);
  fields = setFieldDefaults(fields, fields, module, inheritanceData);
  const {
    fieldLanguage = currentUserLang,
    contentLanguage = currentUserLang
  } = props;
  if (fieldLanguage === masterLanguage && contentLanguage === masterLanguage) return fields;
  const translationsArgs = [fields,
  /* Ensure master language messages is not used */
  fieldLanguage === masterLanguage ? null : getTranslation(messages, fieldLanguage), contentLanguage === masterLanguage ? null : getTranslation(messages, contentLanguage)];

  if (getIsBlogContentModule(moduleId)) {
    return getTranslatedBlogContentFields(...translationsArgs);
  }

  return getTranslatedFields(...translationsArgs);
}
export function getAvailableModuleTabs(props, scopes) {
  const {
    moduleSpec: {
      fields = []
    }
  } = props;
  const moduleTabs = {
    [CONTENT]: false
  };
  const moduleTabs_checked = {};
  fields.forEach(_field => {
    const _tab = _field.tab || CONTENT; //indicates that the editor added visibility field to style tab


    if (_field.internalId === VISIBILITYGROUP) {
      moduleTabs[STYLE] = true;
      moduleTabs_checked[STYLE] = true;
    } else {
      if (!moduleTabs_checked[_tab]) {
        moduleTabs[_tab] = doesTabHaveFields(_tab, props, scopes);
        moduleTabs_checked[_tab] = true;
      }
    }
  });
  return moduleTabs;
} // Taken from UIComponents Props.js utils

/**
 * @param {Object} props
 * @return {Object} The subset of the given props whose names start with `aria-` or `data-`
 */

export const getAriaAndDataProps = props => {
  const propKeyRegex = /^((aria-)|(data-))/;
  return Object.keys(props).reduce((acc, key) => {
    if (!key) return acc;
    if (key.match(propKeyRegex)) acc[key] = props[key];
    return acc;
  }, {});
};
export const getOccurrenceFieldKey = fieldKey => {
  let occurrenceFieldKey = [];

  for (let i = fieldKey.length - 1; i > 0; i--) {
    const currentFieldKey = fieldKey[i];

    if (typeof currentFieldKey === 'number') {
      occurrenceFieldKey = fieldKey.slice(0, i + 1);
      break;
    }
  }

  return occurrenceFieldKey;
};
export const getModuleSlideInState = (prevFieldKey, currFieldKey) => {
  let shouldSlideInFromRight = false;
  let shouldSlideInFromLeft = false;

  if (!prevFieldKey || !currFieldKey) {
    // If a field key is null it means you are either going to or from the highest level
    shouldSlideInFromRight = !!(!prevFieldKey && currFieldKey);
    shouldSlideInFromLeft = !!(prevFieldKey && !currFieldKey);
  } else if (prevFieldKey.length === currFieldKey.length) {
    // Check to see if previous or next occurrence based on occurrence index
    const currOccurrence = getOccurrenceFieldKey(currFieldKey).pop();
    const prevOccurrence = getOccurrenceFieldKey(prevFieldKey).pop();
    shouldSlideInFromRight = currOccurrence > prevOccurrence;
    shouldSlideInFromLeft = currOccurrence < prevOccurrence;
  } else if (currFieldKey.length !== prevFieldKey.length) {
    // Check to see if you've gone up or down a level based on field key length
    shouldSlideInFromRight = currFieldKey.length > prevFieldKey.length;
    shouldSlideInFromLeft = currFieldKey.length < prevFieldKey.length;
  }

  return {
    shouldSlideInFromLeft,
    shouldSlideInFromRight
  };
};
export const areFieldsLocked = fields => {
  return fields.length > 0 && fields.every(field => {
    return field.type === FieldTypes.GROUP ? field.locked || areFieldsLocked(field.children) : field.locked;
  });
};
export const setFieldSpecificProps = (field, {
  contentId,
  contentCategoryName,
  module = {},
  moduleSpec = {},
  onFieldChange,
  pageContext
}) => {
  const {
    path
  } = moduleSpec;

  switch (field.type) {
    case FieldTypes.URL:
    case FieldTypes.LINK:
      {
        return Object.assign({}, field, {
          pageContext
        });
      }

    case FieldTypes.LOGO:
    case FieldTypes.COLOR:
      {
        return Object.assign({}, field, {
          contentId,
          contentCategoryName
        });
      }

    case FieldTypes.FOLLOW_UP_EMAIL:
      {
        if (path === '@hubspot/form' && (Routes.isUngated('ContextualAutomation:FormsEmailsAccess') || Routes.isUngated('ContextualAutomation:LegacyFollowupEmailMigration'))) {
          return Object.assign({}, field, {
            formId: module.form && module.form.form_id,
            followupEmailEnabled: module.follow_up_type_simple
          });
        }

        return field;
      }

    case FieldTypes.FORM:
      {
        const fieldWithContentId = Object.assign({}, field, {
          contentId
        });
        return path === '@hubspot/form' && Routes.isUngated('ContextualAutomation:LegacyFollowupEmailMigration') ? Object.assign({}, fieldWithContentId, {
          clearLegacyFollowupEmail: () => {
            onFieldChange(['follow_up_type_simple'], undefined);
            onFieldChange(['simple_email_for_live_id'], undefined);
          },
          legacyFollowupEmailId: module.follow_up_type_simple ? module.simple_email_for_live_id : null
        }) : fieldWithContentId;
      }

    default:
      return field;
  }
};
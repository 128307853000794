import { parse } from 'hub-http/helpers/params';

const getQueryParam = param => {
  // Accessing `window.top` throws when we're on a
  // different domain (e.g. Wordpress)
  try {
    return parse(window.top.location.search.substring(1))[param];
  } catch (_) {
    return '';
  }
};

export const HAS_ASSIGNABLE_OVERRIDE = getQueryParam('assignable') === 'true';
export const HAS_UNASSIGNABLE_OVERRIDE = getQueryParam('assignable') === 'false';
export const HAS_RETAIL_OVERRIDE = getQueryParam('isRetail') === 'true';
export const HAS_NOT_RETAIL_OVERRIDE = getQueryParam('isRetail') === 'false';
export const HAS_FREE_OVERRIDE = getQueryParam('isFree') === 'true';
export const HAS_PAID_OVERRIDE = getQueryParam('isFree') === 'false';
import { FLINT } from 'HubStyleTokens/colors';
import { ApiMap } from 'tinymce-data/ApiMap';
export const SUPPORTED_LANGS = ['de', 'es', 'ja', 'pt', 'fr']; // Gates and scopes

export const HSVIDEO_SCOPE = 'marketing-video';
export const HS_VIDEO_ADVANCED_LEAD_GEN_SCOPE = 'video-advanced-lead-gen';
export const HSCTA_SCOPE = 'cta-access';
export const FILE_MANAGER_WRITE_SCOPE = 'file-manager-write';
export const HS_MODULE_INSERTION_GATE = 'tinymce_module_insertion';
export const HS_FLEXIBLE_ASSOCIATIONS_GATE = 'flexible-associations';
export const GOOGLE_INCREMENTAL_SCOPING_GATE = 'tinymce_google_incremental_scoping';
export const fontFormatList = {
  'Andale Mono': '"Andale Mono", AndaleMono, "Lucida Console", monospace',
  Arial: 'Arial, Helvetica, sans-serif',
  'Book Antiqua': '"Book Antiqua", Palatino, Georgia, serif',
  'Courier New': '"Courier New", Courier, monospace',
  Georgia: 'Georgia, Palatino, Times, "Times New Roman", serif',
  Helvetica: 'Helvetica, Arial, sans-serif',
  Impact: 'Impact, Chicago, Arial Black, Arial, sans serif',
  Symbol: 'Symbol',
  Tahoma: 'Tahoma, Arial, Helvetica, sans-serif',
  Monaco: 'monaco, monospace',
  Terminal: 'terminal, monospace',
  'Times New Roman': '"Times New Roman", Times, serif',
  'Trebuchet MS': '"Trebuchet MS", Geneva, Tahoma, sans-serif',
  Verdana: 'Verdana, Geneva, sans-serif'
};
export const fontSizeList = ['8', '10', '12', '14', '16', '18', '20', '24', '30', '36', '48', '60', '72'];
export const lineHeightsList = [1, 1.15, 1.25, 1.5, 1.75, 2, 2.5, 3, 4];
// API allow list
export const apiProxyAllowList = Object.keys(ApiMap).map(key => ({
  regex: ApiMap[key].regex,
  methods: ApiMap[key].methods
}));
export const ToolbarControlTypes = {
  BUTTON: 'BUTTON',
  SELECT: 'SELECT',
  DIVIDER: 'DIVIDER',
  MORE_MENU: 'MORE_MENU',
  ADVANCED_MENU: 'ADVANCED_MENU',
  INSERT_MENU: 'INSERT_MENU'
};
export const getDefaultTableStyles = () => ({
  width: '100%',
  'margin-left': 'auto',
  'margin-right': 'auto',
  'border-color': FLINT,
  'border-width': '1px',
  'border-style': 'solid',
  'border-collapse': 'collapse',
  'table-layout': 'fixed'
});
export const getDefaultTableCellStyles = () => ({
  padding: '4px'
});
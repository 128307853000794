'use es6';

import { createSelector } from 'reselect';
import { DEFAULT_REQUIRED_SCOPE } from 'ContentEditorUI/lib/app/constants';
import { createHasGateSelector, createHasScopeSelector, getDomainPageAttachedStylesheetAccess } from 'ContentEditorUI/redux/selectors/authSelectors';
import { getHasLayout } from 'ContentEditorUI/redux/selectors/templateInfoSelectors';
import { getSubcategory } from 'ContentEditorUI/redux/selectors/baseContentModelSelectors';
import { getIsSitePage, getIsBlogListingPage } from 'ContentEditorUI/redux/selectors/contentReadOnlyDataSelectors';
import { SUPPORTED_HUBS, SUPPORTED_HUBS_REQUIRED_SCOPES } from 'collaboration-sidebar';
const getHasLandingPagesAccessScope = createHasScopeSelector(DEFAULT_REQUIRED_SCOPE);
const getHasSitePagesAccessScope = createHasScopeSelector('sitepages-access');
const getIsMigrationUser = createHasScopeSelector('migration-specialist');
export const getHasHidePrimaryCss = createHasGateSelector('DesignManager:IDE:hidePrimaryCss');
export const getHasPermissionsToLoadEditor = createSelector([getIsSitePage, getIsMigrationUser, getHasSitePagesAccessScope, getHasLandingPagesAccessScope], (isSitePage, isMigrationUser, hasSitePagesAccessScope, hasLandingPagesAccessScope) => {
  if (isSitePage) {
    return isMigrationUser || hasSitePagesAccessScope;
  }

  return hasLandingPagesAccessScope;
});
export const getHasAudienceAccessScope = createHasScopeSelector('marketing-content-restricted-write');
export const getHasLandingPagesPublishScope = createHasScopeSelector('landingpages-publish');
export const getHasSitePagesPublishScope = createHasScopeSelector('pages-publish');
export const getCanPublishPages = createSelector([getSubcategory, getHasLandingPagesPublishScope, getHasSitePagesPublishScope], (subcategory, hasLandingPagesPublishScope, hasSitePagesPublishScope) => {
  if (subcategory === 'landing_page') {
    return hasLandingPagesPublishScope;
  }

  return hasSitePagesPublishScope;
});
export const getHasLandingPagesScheduleScope = createHasScopeSelector('landingpages-schedule');
export const getHasSitePagesScheduleScope = createHasScopeSelector('pages-schedule');
export const getCanSchedule = createSelector([getSubcategory, getHasLandingPagesScheduleScope, getHasSitePagesScheduleScope], (subcategory, hasLandingPagesScheduleScope, hasSitePagesScheduleScope) => {
  if (subcategory === 'landing_page') {
    return hasLandingPagesScheduleScope;
  }

  return hasSitePagesScheduleScope;
});
const getHasLandingPagesPasswordProtection = createHasScopeSelector('landingpages-password-protection');
const getHasSitePagesPasswordProtection = createHasScopeSelector('sitepages-password-protection');
export const getHasPasswordProtectionAccess = createSelector([getIsSitePage, getHasLandingPagesPasswordProtection, getHasSitePagesPasswordProtection, getIsBlogListingPage], (isSitePage, hasLandingPagesPasswordProtection, hasSitePagesPasswordProtection, isBlogListingPage) => {
  if (isBlogListingPage) {
    return false;
  }

  if (isSitePage) {
    return hasSitePagesPasswordProtection;
  }

  return hasLandingPagesPasswordProtection;
});
export const getHasMenuWriteAccess = createHasScopeSelector('menus-write');
export const getHasHubDbCustomDomainAccess = createHasScopeSelector('hubdb-custom-domain-access');
export const getHasAnyStylesheetAccess = createSelector([getHasHidePrimaryCss, getHasLayout, getDomainPageAttachedStylesheetAccess], (hasHidePrimaryCss, hasLayout, hasDomainPageAttachedStylesheetAccess) => !hasHidePrimaryCss || hasLayout || hasDomainPageAttachedStylesheetAccess);
export const getHasDynamicPageAccess = createHasScopeSelector('dynamic-page-access');
export const getHasCrmObjectAccess = createHasScopeSelector('crm-object-types-read');
export const getHasCrmDynamicPageAccess = createSelector([getHasDynamicPageAccess, getHasCrmObjectAccess], (hasDynamicPageAccess, hasCrmObjectAccess) => hasDynamicPageAccess && hasCrmObjectAccess);
const getHasCmsHubCommenting = createHasScopeSelector(SUPPORTED_HUBS_REQUIRED_SCOPES[SUPPORTED_HUBS.CMS_HUB]);
const getHasMarketingHubCommenting = createHasScopeSelector(SUPPORTED_HUBS_REQUIRED_SCOPES[SUPPORTED_HUBS.MARKETING_HUB]);
export const getHasCommenting = createSelector([getIsSitePage, getHasCmsHubCommenting, getHasMarketingHubCommenting], (isSitePage, hasCmsHubCommenting, hasMarketingHubCommenting) => {
  return isSitePage ? hasCmsHubCommenting : hasCmsHubCommenting || hasMarketingHubCommenting;
});
export const getIsCmsWootricSurveyEnabled = createHasGateSelector('ContentEditor:WootricSurveyEnabled');
export const getIsBaymaxWootricSurveyEnabled = createHasGateSelector('BaymaxContentEditor:WootricSurveyEnabled');
export const getIsUngatedForTemporarySlugChanges = createHasGateSelector('CMS:TemporarySlug');
export const getIsUngatedForProductApprovalsRepublishing = createHasGateSelector('ProductApprovals:Republishing');